import * as React from 'react'
import styled from 'styled-components'

import Layout from '../layout/index'
import Navbar from '../components/Navbar'
import Logo from '../images/social-media/whatsapp-icon.png'
import LogoFacebook from '../images/social-media/facebook-icon.svg'
import LogoInstagram from '../images/social-media/instagram-icon.svg'
import { media } from '../styles/styleUtils'

interface IndexPageProps {
  location: {
    pathname: string
  }
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
      }
    }
  }
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  //background: #ffffff;
`
export const ReservasWrapper = styled.a`
  width: 20%;
  top: 0.5em;
  right: 8em;
  background-color: #111010;
  display: flex;
  padding: 0.5rem;
  font-weight: bold;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  opacity: 0.7;
  font-size: 0.8rem;
  cursor: pointer;
  &:hover {
    background-color: white;
    border: solid 2px black;
    color: black;
  }
  ${media.lg`
    display:none;
  `}
`

export const WhastappLogo = styled.img`
  width: 1.5rem;
  margin-right: 2px;
`

export const WhatsappWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export default ({ data, location }: IndexPageProps) => {
  return (
    <Layout location={location} data={data}>
      <Wrapper>
        <Navbar />
      </Wrapper>
    </Layout>
  )
}
